import React, { useEffect, useState } from 'react'
import './oldScreen.css'
import oldPc from '../../accets/IMGs/PC.png'
import mask from './mask.png'
import MiniAppLogic from '../miniAppLogic'
import { useScroll } from '../../Hooks/useScroll'

function OldScreen({setVariant}) {

  const [t, setT] = useState(true)
  const { scrollToPage } = useScroll()

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setT(false)
    })
    window.addEventListener('keypress', (e) => {
      if (e.keyCode == 13) {
        setT(true)
      }
    })
  }, [])

  useEffect(() => {
    const refBtn = document.getElementById('time4')
    refBtn.addEventListener('click', e => {
      setVariant(null)
    })
  }, []) 

  return (
    <div onClick={() => scrollToPage(2)} className='w-screen h-screen flex items-center justify-between overflow-hidden'>
      <div className='w-full h-full font-robot'>
        <div className='old-screen'>
          <img src={oldPc} className='scan'/>
          <img src={mask} className=' z-50 w-[88%] h-[87%]'/>
          <div className='w-full h-full absolute py-[5.5em] px-[6.5em] z-[60]'>
            {/* <div className='w-full h-[95%] px-[6em] py-[4em] overflow-hidden'>
              {window.scrollY === window.innerHeight * 2 ?
                t?
                  <MiniAppLogic/>
                  :
                  <div className='text-old text-[5em] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'>Press Enter button to start</div>
                :
                <div className='text-old text-[5em] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'>Off</div>
              }
            </div> */}
          <div className='w-[120vw] absolute  translate-x-[-50%] translate-y-[-50%] top-[50%] left-[50%] h-[150px] bg-text_primary rotate-[-30deg] flex flex-row justify-between items-center'>
            {
              [1, 2, 3, 4, 5, 6, 7, 8].map(() => {
                return (
                  <div>
                    <h1 className=' text-bg_primary text-3xl font-bold font-main'>cooming soon...</h1>
                  </div>
                )
              })
            }
          </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OldScreen