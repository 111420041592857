import { useState } from "react"
import img1 from '../accets/IMGs/img1.jpg'
import img2 from '../accets/IMGs/img2.jpg'
import img3 from '../accets/IMGs/img3.jpg'
import img4 from '../accets/IMGs/img4.jpg'
import imgLogo from '../accets/IMGs/icon_r50_512 1.png'

export const useProjects = () => {
    const link = 'https://vk.com/services?ref=catalog_recent&w=app51780803_467966674'
    const projects = [
        {   
            author: 'Team N&D, Огнев Никита, Вовчинский Дмитрий',
            title: 'EvenTracker VK mini app',
            topic: 'Уникальный навигатор твоего времени, который поможет найти крутые мероприятия и классную компанию.',
            img: img1,
            des: 'Приложение, которое делает поиск мероприятий увлекательным и удобным благодаря множеству инструментов. Оно также объединяет пользователей в сообщества по интересам, что позволяет им найти единомышленников и познакомиться с новыми людьми.',
            id: 1,
            logo: imgLogo,
            link: link,
            date: '2023-2024',
            rating: '8',
            
        },
        {   
            author: 'Team N&D, Огнев Никита, Вовчинский Дмитрий',
            title: 'EvenTracker native mobile app',
            topic: 'Уникальный навигатор твоего времени, который поможет найти крутые мероприятия и классную компанию.',
            img: img1,
            des: 'Приложение, которое делает поиск мероприятий увлекательным и удобным благодаря множеству инструментов. Оно также объединяет пользователей в сообщества по интересам, что позволяет им найти единомышленников и познакомиться с новыми людьми.',
            id: 2,
            logo: imgLogo,
            link: link,
            date: '2023-2024',
            rating: '8',
            
        },
        {   
            author: 'Team N&D, Огнев Никита, Вовчинский Дмитрий',
            title: 'EvenTracker web app',
            topic: 'Уникальный навигатор твоего времени, который поможет найти крутые мероприятия и классную компанию.',
            img: img1,
            des: 'Приложение, которое делает поиск мероприятий увлекательным и удобным благодаря множеству инструментов. Оно также объединяет пользователей в сообщества по интересам, что позволяет им найти единомышленников и познакомиться с новыми людьми.',
            id: 3,
            logo: imgLogo,
            link: link,
            date: '2023-2024',
            rating: '8',
            
        },
        {   
            author: 'Team N&D, Огнев Никита, Вовчинский Дмитрий',
            title: 'Alumni telegram mini app',
            topic: 'Уникальный навигатор твоего времени, который поможет найти крутые мероприятия и классную компанию.',
            img: img1,
            des: 'Приложение, которое делает поиск мероприятий увлекательным и удобным благодаря множеству инструментов. Оно также объединяет пользователей в сообщества по интересам, что позволяет им найти единомышленников и познакомиться с новыми людьми.',
            id: 4,
            logo: imgLogo,
            link: link,
            date: '2023-2024',
            rating: '8',
            
        },
        {   
            author: 'Team N&D, Огнев Никита, Вовчинский Дмитрий',
            title: 'Surgu Events web app',
            topic: 'Уникальный навигатор твоего времени, который поможет найти крутые мероприятия и классную компанию.',
            img: img1,
            des: 'Приложение, которое делает поиск мероприятий увлекательным и удобным благодаря множеству инструментов. Оно также объединяет пользователей в сообщества по интересам, что позволяет им найти единомышленников и познакомиться с новыми людьми.',
            id: 5,
            logo: imgLogo,
            link: link,
            date: '2023-2024',
            rating: '8',
            
        },
        {   
            author: 'Team N&D, Огнев Никита, Вовчинский Дмитрий',
            title: 'Surgu Events native mobile app',
            topic: 'Уникальный навигатор твоего времени, который поможет найти крутые мероприятия и классную компанию.',
            img: img1,
            des: 'Приложение, которое делает поиск мероприятий увлекательным и удобным благодаря множеству инструментов. Оно также объединяет пользователей в сообщества по интересам, что позволяет им найти единомышленников и познакомиться с новыми людьми.',
            id: 6,
            logo: imgLogo,
            link: link,
            date: '2023-2024',
            rating: '8',
            
        },
        {   
            author: 'Team N&D, Огнев Никита, Вовчинский Дмитрий',
            title: 'Friendify native mobile app',
            topic: 'Уникальный навигатор твоего времени, который поможет найти крутые мероприятия и классную компанию.',
            img: img1,
            des: 'Приложение, которое делает поиск мероприятий увлекательным и удобным благодаря множеству инструментов. Оно также объединяет пользователей в сообщества по интересам, что позволяет им найти единомышленников и познакомиться с новыми людьми.',
            id: 7,
            logo: imgLogo,
            link: link,
            date: '2023-2024',
            rating: '8',
            
        },
        {   
            author: 'Team N&D, Огнев Никита, Вовчинский Дмитрий',
            title: 'Friendify web app',
            topic: 'Уникальный навигатор твоего времени, который поможет найти крутые мероприятия и классную компанию.',
            img: img1,
            des: 'Приложение, которое делает поиск мероприятий увлекательным и удобным благодаря множеству инструментов. Оно также объединяет пользователей в сообщества по интересам, что позволяет им найти единомышленников и познакомиться с новыми людьми.',
            id: 8,
            logo: imgLogo,
            link: link,
            date: '2023-2024',
            rating: '8',
            
        },
        {   
            author: 'Team N&D, Огнев Никита, Вовчинский Дмитрий',
            title: 'Ognev site',
            topic: 'Уникальный навигатор твоего времени, который поможет найти крутые мероприятия и классную компанию.',
            img: img1,
            des: 'Приложение, которое делает поиск мероприятий увлекательным и удобным благодаря множеству инструментов. Оно также объединяет пользователей в сообщества по интересам, что позволяет им найти единомышленников и познакомиться с новыми людьми.',
            id: 9,
            logo: imgLogo,
            link: link,
            date: '2023-2024',
            rating: '8',
            
        },
    ]

    /**
     * @id
     */
    const getPrById = (id) => {
        let res = projects.filter((item) => item.id == id)
        return res
    }


    return {
        projects,
        getPrById,
    }
}