import React, { useEffect, useState } from 'react'
import AuthorTab from '../tabs/AuthorTab'
import AchivmentsTab from '../tabs/AchivmentsTab'
import ProjectsTab from '../tabs/ProjectsTab'
import ContactsTab from '../tabs/ContactsTab'
import { Icon12InfoCircle } from '@vkontakte/icons'
import { motion } from 'framer-motion'

function HomePage() {

  const [modal, setModal] = useState(false)
  const [hide, setHide] = useState(false)

  const show = (type) => {
    setModal(type)
  }

  useEffect(() => {
    setTimeout(() => {
      setHide(true)
    }, 10000)
  }, [])

  return (
    <div>
      <AuthorTab/>
      <ProjectsTab/>
      <AchivmentsTab/>
      <ContactsTab/> 
      <div className={hide? 'w-full h-[50px] fixed bg-bg_secondary bottom-0 flex items-center justify-center z-[100] opacity-15': 'w-full h-[50px] fixed bg-bg_secondary bottom-0 flex items-center justify-center z-[100]'}>
        <div className='w-[90%] flex flex-row justify-between items-center'>
          <h1 className=' text-[#b1b1b1]'>
            site is under construction 
          </h1>
          <motion.div
            onHoverEnd={() => show(false)}
            onHoverStart={() => show(true)}
          >
            <Icon12InfoCircle width={25} height={25} color='#b1b1b1'/>
          </motion.div>
        </div>
        {
          modal?
          <div className='w-auto h-auto bg-bg_secondary fixed translate-x-[-5%] translate-y-[-50%] right-0 bottom-[5vh] px-[2vw] py-[2vh] rounded-2xl'>
            <div>
              <h1>
                You may see unimaginable things, bugs and more, please be understanding.
              </h1>
            </div>
          </div>
          :
          <></>
        }
      </div>
    </div>
  )
}

export default HomePage