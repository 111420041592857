import { useEffect, useState } from "react"

export const useLS = () => {
    const [light, setLight] = useState(true)
    const [firstOpen, setFirstOpen] = useState(null)
    const first_open_key = 'first_open'

    const checkFirstOpen = () => {
        if (localStorage.getItem(first_open_key) === null) {
            setFirstOpen(true)
        } else {
            setFirstOpen(false)
        }
    }
    useEffect(() => {
        checkFirstOpen()
    }, [])

    const updateFirstOpen = () => {
        localStorage.setItem(first_open_key, 'false')
        setFirstOpen(false)
    }

    const clearData = () => {
        localStorage.clear()
        window.location.reload()
    }

    const saveLastPage = (page) => {
        localStorage.setItem('page', String(page))
    }
    const getLastPage = () => {
        if (localStorage.getItem('page') === null) {

        } else {
            return localStorage.getItem('page')
        }
    }
    const menuState = () => {
        if (sessionStorage.getItem('menu') === null) {
            sessionStorage.setItem('menu', 'true')
            return 'false'
        } else {
            return sessionStorage.getItem('menu')
        }
    }

    return {
        menuState,
        getLastPage,
        saveLastPage,
        clearData,
        updateFirstOpen,
        light,
        firstOpen,
        setLight,
    }
}