import React, { useEffect, useState } from 'react'
import { useHref, useNavigate, useParams } from 'react-router'
import { useProjects } from '../Hooks/useProjects'
import { Icon12Cancel } from '@vkontakte/icons'

function ProjectPage() {
  const { id } = useParams()
  const { getPrById } = useProjects()
  const [project, setProject] = useState(null)
  const navigate = useNavigate()
  useEffect(() => {
    setProject(getPrById(id)[0])
  }, [id])

  const handleClick = (e) => {
    e.preventDefault();
    window.location.href=`${project.link}`
  };
  return (
    project === null ?
    <></>
    :
    <div className='w-full h-full min-h-screen bg-bg_primary flex flex-col items-center justify-center'>
      <h1 className='text-5xl font-bold'>comming soon...</h1>
    </div>
  )
}

export default ProjectPage