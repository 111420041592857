import { useEffect } from "react"
import { useLS } from "./useLS"
import { isMobile } from "react-device-detect"

export const useAnimation = () => {
  const { firstOpen, updateFirstOpen } = useLS()
  const textAnimation = {
      hidden: {
        x: -100,
        opacity: 0,
      },
      visible: custom => ({
        x: 0, 
        opacity: 1,
        transition: { delay: custom * 0.2 }
      })
    }

  return {
      textAnimation,  
  }
}