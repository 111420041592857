import React, { useEffect, useState } from 'react'
import { useScroll } from '../Hooks/useScroll'
import { motion } from 'framer-motion'
import { useAnimation } from '../Hooks/useAnimation'
import { Icon12Message, Icon16LogoVk, Icon16MailOutline, Icon16Phone, Icon16Search, Icon24LogoInstagram } from '@vkontakte/icons'
import Badge from '../components/bage/badge'
import { isMobile } from 'react-device-detect'
import MainWriter from '../components/MainWriter'
import '../css/main.css'

function AuthorTab() {

  const { textAnimation } = useAnimation()
  const { scrollToPage } = useScroll()
  const [draged, setDraged] = useState(false)

  const handleTouch = () => {
    setDraged(true)
  }

  useEffect(() => {
    if (isMobile) return
    if (draged === false) return
    let timer = setTimeout(() => {
      setDraged(false)
    }, 4000)
  }, [draged]);

  useEffect(() => {
    if (isMobile) return
    const text = document.querySelector('.drag-me')
    draged? text.classList.remove('show') : text.classList.add('show')
  }, [draged])

  return (
    isMobile?
    <div className='page_format flex flex-col items-center justify-center'>   
      <div className='w-full self-center font-main h-auto items-center flex flex-col'>
        <div className='w-[90vw] flex flex-col items-start h-full'>
          <div className=''>
            <h1 className='text_prime text-7xl font-bold'>HI, It's</h1>
            <h1 className='text-text_accented font-extrabold text-7xl'>Nikita</h1>
          </div>
          <div className='flex gap-x-5 my-[2vh] w-full h-[10vh] items-start  z-[60] flex-row justify-start'>
            <MainWriter/>
          </div>
          <div className='z-[60] mb-[4vh]'>
            <h1 className='text text-text_tertiary text-xl w-[90vw]'>
              I am a front-end developer, I have experience in project management and implementation of IT startups, at the moment I am 17, I was born in Surgut, Russia.
            </h1>
          </div>
          <div className='z-[100] flex flex-col'>
            <div className='contact-box'>
              <div onClick={() => scrollToPage(3.4)} className='icon-wrapper-m'>
                <Icon16MailOutline width={35} height={35} className='icon'/>
              </div>
              <div onClick={() => scrollToPage(3.4)} className='icon-wrapper-m'>
                <Icon16LogoVk width={35} height={35} className='icon'/>
              </div>
              <div onClick={() => scrollToPage(3.4)} className='icon-wrapper-m'>
                <Icon24LogoInstagram width={35} height={35} className='icon'/>
              </div>
              <div onClick={() => scrollToPage(3.4)} className='icon-wrapper-m'>
                <Icon12Message width={35} height={35} className='icon'/>
              </div>            
            </div>
            <div className='btn-group'>
              <div onClick={() => scrollToPage(1)} className='btn-m'>
                <h1>Hire</h1>
              </div>
              <div onClick={() => scrollToPage(3)} className='btn-m'>
                <h1>Contact</h1>
              </div>
            </div>
          </div> 
        </div>
      </div>
    </div>
    :
    <div className='page_format'>   
    <motion.section className='author_page_main_container z-[35] select-none'
    initial='hidden'
    whileInView='visible'
    >
      <div className='w-full self-center ml-[10vw] font-main h-auto flex flex-col'>
        <div className='w-[45%] flex flex-col items-start h-full'>
          <div className='mt-12 gap-x-[10px]  z-[60] flex flex-row items-baseline'>
            <h1 className='text_prime text-7xl font-bold'>HI, It's</h1>
            <h1 className='text-text_accented font-extrabold text-7xl'>Nikita</h1>
          </div>
          <div className='flex gap-x-5 mt-[1vh]  z-[60] flex-row items-center'>
            <MainWriter/>
          </div>
          <div className='z-[60] my-[4vh]'>
            <h1 className='text text-text_tertiary text-xl w-[30vw]'>
              I am a front-end developer, I have experience in project management and implementation of IT startups, at the moment I am 17, I was born in Surgut, Russia.
            </h1>
          </div>
          <div className='z-[100] flex flex-col'>
            <div className='contact-box'>
              <div onClick={() => scrollToPage(3.2)} className='icon-wrapper'>
                <Icon16MailOutline width={35} height={35} className='icon'/>
              </div>
              <div onClick={() => scrollToPage(3.2)} className='icon-wrapper'>
                <Icon16LogoVk width={35} height={35} className='icon'/>
              </div>
              <div onClick={() => scrollToPage(3.2)} className='icon-wrapper'>
                <Icon24LogoInstagram width={35} height={35} className='icon'/>
              </div>
              <div onClick={() => scrollToPage(3.2)} className='icon-wrapper'>
                <Icon12Message width={35} height={35} className='icon'/>
              </div>            
            </div>
            <div className='btn-group'>
              <div onClick={() => scrollToPage(1)} className='btn'>
                <h1>Hire</h1>
              </div>
              <div onClick={() => scrollToPage(3)} className='btn'>
                <h1>Contact</h1>
              </div>
            </div>
          </div> 
        </div>
      </div>
      <div onClick={handleTouch} className='w-screen absolute overflow-x-hidden h-screen top-0 z-[50]'>
        <Badge />
        <div className='absolute top-[82%] left-[72.5%] z-[80] translate-x-[-50%] translate-y-[-50%]'>
          <h1 className='text-text_accented font-semibold text-[2rem] drag-me'>drag me:)</h1>
        </div>
      </div>
    </motion.section>
    </div>
  )
}

export default AuthorTab