import React, { useEffect, useState } from 'react'
import '../css/main.css'
import { Icon12Cancel, Icon20LightbulbOutline } from '@vkontakte/icons'
import { useNavigate } from 'react-router'
import { useProjects } from '../Hooks/useProjects'
import { useText } from '../Hooks/useText'
import { isMobile } from 'react-device-detect'


function ProjectsPage() {
  const navigate = useNavigate()
  const { projects } = useProjects()
  const { refactorText } = useText()

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    })
  }, [])

  return (
    isMobile?
    <div className='w-full h-screen flex flex-col gap-y-10 items-center justify-center text-3xl font-bold'>
      comming soon...
      <button onClick={() => navigate(-1)}>
        back 
      </button>
    </div>
    :
    <div className='page cursor-pointer select-none w-full h-full bg-bg_primary pt-5 pb-[15vh] scroll-smooth pl-[5vw] pr-[5vw]'>
      <div className='w-full text-text_primary mb-[3vh] py-[25px] z-[100] m-auto items-center'>
        <div className='flex flex-row w-full justify-between'>
          <h1 className='text-5xl font-bold'>Мои проекты</h1>
          <div>
            <button onClick={() => navigate(-1)}>
              <Icon12Cancel width={40} height={40}/>
            </button>
          </div>
        </div>
        <div className='w-[5%] mt-[10px] h-[5px] projects-underline rounded-2xl'/>
      </div>
      <div className='content-neon'>
          {projects.map((item, index) => {
            return (
              <div key={crypto.randomUUID()} className={'parent-neon'}>
                <div onClick={() => navigate(`/project/${item.id}`)} className='card' style={{backgroundImage: `url(${item.img})`, '--p': `${index}`}}>
                  <div className='absolute top-0 left-0 w-full h-full bg-bg_primary opacity-50 z-[10] rounded-[10px]'/> 
                  <div className='relative z-50 flex flex-col min-h-[14vh] mt-[5vh] h-full gap-y-[2vh] overflow-hidden break-words p-[25px]'>
                    <div className=''>
                      <h1 className='text-[2rem] font-extrabold'>
                        {item.title}
                      </h1>
                    </div>
                    <div className=''>
                      <h1 className='text-[1rem]'>
                        {refactorText(item.topic, 90)}
                      </h1>
                    </div>
                    <div className=''>
                      <button className='see-more'>
                        <h1>see more</h1>
                      </button>
                    </div>
                  </div>
                  <div className='date-box relative z-[20]'>
                    <span className='month'>Rating</span>
                    <span className='date'>{item.rating} / 10</span>
                  </div>
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default ProjectsPage