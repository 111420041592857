import React from 'react';
import ReactDOM, { createRoot } from 'react-dom/client';
import ErrorPage from './error-page';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import './index.css'
import HomePage from './pages/HomePage'
import ProjectsPage from './pages/ProjectsPage';
import MainPageLayout from './MainPageLayout';
import ProjectPage from './pages/ProjectPage';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/home" replace={true} />,
    errorElement: <ErrorPage />,
  },   
  {
    element: <MainPageLayout/>,
    children: [
      {
        path: "/home",
        element: <HomePage />,
      }, 
      {
        path: '/projects',
        element: <ProjectsPage/>
      },
      {
        path: '/project/:id',
        element: <ProjectPage/>
      },
    ],
  },
]);

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <RouterProvider router={router} />
);

