export const useText = () => {

    /**
     * 
     * @param {text} 
     */
    const refactorText = (text, length) => {
        let res;
        if (text.length > length) {
            res = text.slice(0, length)
            res = res + '...'
        } else {
            res = text
        }
        return res
    }


    return {
        refactorText
    }
}