import React, { useEffect, useState } from 'react'
import { useScroll } from '../Hooks/useScroll'
import { useAchivements } from '../Hooks/useAchivements'
import { isMobile } from 'react-device-detect'
import { motion } from 'framer-motion'
import OldScreen from '../components/oldScreen/oldScreen'
import { useLS } from '../Hooks/useLS'
import TimeLine from '../components/ExpirienceTimeLine/TimeLine'
import { nativeFn } from 'three/examples/jsm/nodes/code/FunctionNode.js'

function AchivmentsTab() {  

  const {achivements, education, workExperience} = useAchivements()
  const { scrollToPage, setCurrentPage } = useScroll()
  const [variant, setVariant] = useState()

  return (
    isMobile?
    <div className='page_format'>
      <div className='w-full h-full flex flex-row justify-center items-center'>
        <h1 className='text-5xl font-bold'>comming soon...</h1>
      </div>
    </div>
    :
    <div onClick={() => scrollToPage(2)} className='page_format'>
      {variant? 
      variant === 'new' ?
      <div className='w-full h-screen flex items-center justify-center'>
        <TimeLine setVariant={setVariant}/>
      </div>
      :
      <div>
        <OldScreen setVariant={setVariant}/>
      </div>
      :
      <div className='w-full h-full flex flex-col items-center justify-center select-none'>
        <div className='flex flex-row text-[4rem] font-extrabold items-center gap-x-[2vh]'>
          <h1 onClick={() => setVariant('old')} className='cursor-pointer'>OLD</h1>
          <div className='w-[0.1rem] h-[5rem] bg-text_primary'/>
          <h1 onClick={() => setVariant('new')} className='text-text_accented cursor-pointer'>NEW</h1>
        </div>
        <h1 className='text-[2rem] font-bold'>
          Choose variant
        </h1>
      </div>
      }

    </div>
  )
}

export default AchivmentsTab