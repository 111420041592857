import { Button, animate } from '@vkontakte/vkui'
import { useScroll } from '../Hooks/useScroll'
import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { Icon12ArrowDown, Icon12ArrowUp, Icon12Cancel, Icon12Phone, Icon16Phone, Icon24Back, Icon24Refresh, Icon24Settings } from '@vkontakte/icons'
import { useNavigate } from 'react-router'
import { isMobile } from 'react-device-detect'
import { add } from 'three/examples/jsm/nodes/Nodes.js'

export let prevNav;

function NavBar() {
  const { scrolledPercent, currentPage, scrollToPage, authorTabRef, achivmentsTabRef, projectsTabRef, } = useScroll()
  const [path, setPath] = useState()
  const [prevPage, setPrevPage] = useState(currentPage)
  const [pageName, setPageName] = useState(null)
  
  useEffect(() => {
    setPath(window.location.pathname)
  }, [window.location.pathname])

  const navigate = useNavigate()

  useEffect(() => {
    if (window.location.pathname.includes('project')) {
      setPath('/project')
    }
  }, [path])

  useEffect(() => {
    if (path === '/projects') return
    if (path === '/project') return
    if (isMobile) return
    const nav = document.getElementById('navBar')
    if ((Math.abs((window.scrollY / window.innerHeight) - currentPage )) > 0.4) return
    let time = setTimeout(() => {
      (window.scrollY / window.innerHeight) > 3 ? <></> : scrollToPage(currentPage)
    }, 1000)
    window.addEventListener('scroll', () => {
      clearTimeout(time)
      nav.classList.remove('hidden-navBar')
    })
  }, [window.scrollY])

  useEffect(() => {
    if (path === '/projects') return
    if (path === '/project') return
    const button = document.getElementById(`nav-bar-${currentPage}`)
    if (button === null) return
    button.classList.add('selected')
    if (currentPage === 0 && prevPage === 0) return
    setPrevPage(currentPage)
    const prevButton = document.getElementById(`nav-bar-${prevPage}`)
    prevButton.classList.remove('selected')
  }, [currentPage])

  const handleNavStateSelect = (type, id) => {
    const ach = document.getElementById('achiv1')
    ach.classList.add(type)
    prevNav = type
  }

  useEffect(() => {
    const buttons = document.querySelector('.achivements-content')
    if (buttons === null) return
    if (currentPage === 2) {
      buttons.classList.add('show')
    } else {
      buttons.classList.remove('show')
    }
  }, [currentPage])

  useEffect(() => {
    if (currentPage === undefined) return
    switch (currentPage) {
      case 0:
        setPageName('home')
        break;
      case 1:
        setPageName('projects')
        break;
      case 2:
        setPageName('expirience')
        break;
      case 3:
        setPageName('contacts')
        break;
      default:
        break;
    }
  }, [currentPage])

  switch (path) {
    case '/project':
      return (
        <div id='navBar'>
        </div>
      )
    default:
      return (
        isMobile?
        <div id='navBar' className='nav_bar'>
          <div className='nav_bar_wrapper w-full flex flex-col items-center'>
            <div className='w-[90vw] flex flex-row items-center justify-between h-full'>
              <div>
                <h1 className='text-3xl font-bold'>{pageName}</h1>
              </div>
              <div>

              </div>
            </div>
          </div>
        </div>
        :
        <div id='navBar' className='nav_bar duration-500 font-main select-none'>
          <div className='nav_bar_wrapper'>
            <div onClick={() => scrollToPage(0)} className='select-none gap-x-[5px] items-baseline flex flex-row duration-300 hover:duration-300 hover:scale-110'>
              <h1 className='text_prime text-3xl font-bold'>Nikita</h1>
              <h1 className='text-text_accented text-3xl font-bold second-name'>Ognev</h1>
            </div>
            <div className='nav_bar_buttons_container'> 
              <div id='achiv1' className='achivements w-[40vw] border-l-2 border-r-2 mx-[5vh]'>
                <div className='flex flex-row items-center justify-around w-[90%] achivements-content'>
                  <h1 id='time1' onClick={() => handleNavStateSelect('education', 'nav-time-0')} className='nav-bar-button'>Education</h1>
                  <h1 id='time2' onClick={() => handleNavStateSelect('work', 'nav-time-1')} className='nav-bar-button'>Work</h1>
                  <h1 id='time3' onClick={() => handleNavStateSelect('expirience', 'nav-time-2')} className='nav-bar-button'>Achivements</h1>
                  <div id='time4' className='nav-bar-button' onClick={() => handleNavStateSelect('refresh', 'nav-time-3')}><Icon24Refresh/></div>
                </div>  
                </div>
              <div className='w-[50vw] flex justify-between'>
                <div onClick={() => scrollToPage(0)} id='nav-bar-0' className='nav-bar-button'>
                  <h1 className='text'>Home</h1>
                </div>
                <div onClick={() => scrollToPage(1)} id='nav-bar-1' className='nav-bar-button'>
                  <h1 className='text'>Projects</h1>
                </div>
                <div onClick={() => scrollToPage(2)} id='nav-bar-2' className='nav-bar-button'>
                  <h1 className='text'>Expirience</h1>
                </div>
                <div onClick={() => scrollToPage(3)} id='nav-bar-3' className='nav-bar-button'>
                  <h1 className='text'>Contact</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
  }

}

export default NavBar