import React, { useEffect, useState } from 'react'
import './TimeLine.css'
import { useAchivements } from '../../Hooks/useAchivements'
import { prevNav } from '../NavBar'

function TimeLine({setVariant}) {

  const {achivements, education, workExperience} = useAchivements()
  const [arr, setArr] = useState(education)
  const nav = document.getElementById('navBar')

  let prev;
  useEffect(() => {
    nav.addEventListener('click', e => {
      prev ? document.getElementById(prev).classList.remove('selected') : <></>
      e.target.classList.add('selected')
      prev = e.target.id
      switch (e.target.id) {
        case 'time1':
          setArr(education)
          break;

        case 'time2':
          setArr(workExperience)
          break;

        case 'time3':
          setArr(achivements)
          break;

        default:
          break;
      }
    })
  }, []) 

  useEffect(() => {
    const refBtn = document.getElementById('time4')
    refBtn.addEventListener('click', e => {
      setVariant(null)
    })
  }, []) 

  useEffect(() => {
    document.getAnimations().forEach((anim) => {
      if (anim.animationName === 'render') {
        anim.cancel();
        anim.play();
      }
    })
  }, [arr])

  return (
    <div className='w-[90%] h-screen overflow-scroll timeline-wrapper'>
      <div className='timeline-items'>
        {arr.map((item, index) => {
          return (
          <div key={crypto.randomUUID()} className='timeline-item'>
            <div className='timeline-dot'></div>
            <div className='timeline-date'>{item.date}</div>
            <div className='timeline-content'>
              <h1>{item.title}</h1>
              <p>{item.des}</p>
            </div>
          </div>
          )
        })}
      </div>
    </div>
  )
}

export default TimeLine