import React, { useEffect, useState } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import ReCAPTCHA from 'react-google-recaptcha';
import '../../css/main.css'
import { isMobile } from 'react-device-detect';
function ContactForm() {
  const [state, handleSubmit] = useForm("xjkbgzje");
  const [capVal, setCaptVal] = useState(null)

  if (state.succeeded) {
    return (
      <div className='w-full h-screen flex flex-col items-center justify-center'>
        <div className='flex flex-row gap-x-[1rem]'>
          <h1 className='text-[5rem] font-bold'>Your message has been</h1>
          <h1 className='text-[5rem] font-bold text-text_accented'>Forwarded</h1>
        </div>
        <h1 className='text-[3rem] font-semibold'>Thank you for addressing!</h1>
        <button onClick={() => window.location.reload()} className='submit-btn mt-[5vh]'>GOOD</button>
      </div>
    )
  }
  return (
    isMobile?
    <div className='w-full h-[80vh] flex flex-col items-center justify-start mt-[8vh]'>
      <div className='flex flex-row gap-x-[1.5rem] text-[3rem] font-extrabold'>
        <h1>Contact</h1>
        <h1 className='text-text_accented'>Me</h1>
      </div>
      <form onSubmit={handleSubmit} className='flex flex-col items-center justify-start w-full mt-[2vh]'>
        <div className='flex flex-col gap-x-[2rem] w-[90vw] items-center justify-center gap-y-6 '>
          <div className='input-base-wrapper-m'>
            <input
              id="full-name"
              type="full-name" 
              name="full-name"
              className='input-base'
              placeholder='Full Name'
            />
            <ValidationError 
              prefix="full-name" 
              field="full-name"
              errors={state.errors}
            />
          </div>
          <div className='input-base-wrapper-m'>
            <input
              id="e-mail"
              type="e-mail" 
              name="e-mail"
              className='input-base'
              placeholder='E-Mail'
            />
            <ValidationError 
              prefix="full-name" 
              field="full-name"
              errors={state.errors}
            />
          </div>
          <div className='input-base-wrapper-m'>
            <input
              id="phone-number"
              type="phone-number" 
              name="phone-number"
              className='input-base'
              placeholder='Phone Number'
            />
          </div>
          <div className='input-base-wrapper-m'>
            <input
              id="subject"
              type="subject" 
              name="subject"
              className='input-base'
              placeholder='Subject'
            />
            <ValidationError 
              prefix="full-name" 
              field="full-name"
              errors={state.errors}
            />
          </div>
          </div>
          <div className='input-main-wrapper-m mt-[1.5rem]'>
            <textarea
              id="message"
              name="message"
              placeholder='Your message'
              className='input-main'
              style={{resize: 'none'}}
            />
            <ValidationError 
              prefix="Message" 
              field="message"
              errors={state.errors}
            />
          </div>
          <div className='flex flex-col w-[90%] justify-between items-center mt-[2vh]'>
            <ReCAPTCHA
              sitekey='6LdSWxgqAAAAABDyh_cM-D8ZohPFuPdTqQ_ISfuI'
              onChange={val => setCaptVal(val)}
            />
            <div className='mt-[2vh]'>
              <button type="submit" disabled={!capVal} className='submit-btn'>
                Send Message
              </button>
            </div>
          </div>
      </form>
    </div>
    :
    <div className='w-full h-[80vh] flex flex-col items-center justify-start mt-[10vh]'>
    <div className='flex flex-row gap-x-[1.5rem] text-[5rem] font-extrabold'>
      <h1>Contact</h1>
      <h1 className='text-text_accented'>Me</h1>
    </div>
    <form onSubmit={handleSubmit} className='flex flex-col items-center justify-start w-full mt-[2vh]'>
      <div className='flex flex-row gap-x-[2rem] w-[80%] items-center justify-center '>
        <div className='flex flex-col text-text_accented justify-between items-center h-[90%]' >
          <div className='input-base-wrapper'>
            <input
              id="full-name"
              type="full-name" 
              name="full-name"
              className='input-base'
              placeholder='Full Name'
            />
            <ValidationError 
              prefix="full-name" 
              field="full-name"
              errors={state.errors}
            />
          </div>
          <div className='input-base-wrapper'>
            <input
              id="e-mail"
              type="e-mail" 
              name="e-mail"
              className='input-base'
              placeholder='E-Mail'
            />
            <ValidationError 
              prefix="full-name" 
              field="full-name"
              errors={state.errors}
            />
          </div>
          <div className='input-base-wrapper'>
            <input
              id="phone-number"
              type="phone-number" 
              name="phone-number"
              className='input-base'
              placeholder='Phone Number'
            />
          </div>
          <div className='input-base-wrapper'>
            <input
              id="subject"
              type="subject" 
              name="subject"
              className='input-base'
              placeholder='Subject'
            />
            <ValidationError 
              prefix="full-name" 
              field="full-name"
              errors={state.errors}
            />
          </div>
        </div>
        <div className='flex flex-col justify-center items-center'>
          <div className='input-main-wrapper '>
            <textarea
              id="message"
              name="message"
              placeholder='Your message'
              className='input-main max-w-full'
              style={{resize: 'none'}}
            />
            <ValidationError 
              prefix="Message" 
              field="message"
              errors={state.errors}
            />
          </div>
          <div className='flex flex-col w-[90%] justify-between items-center mt-[2vh]'>
            <ReCAPTCHA
              sitekey='6LdSWxgqAAAAABDyh_cM-D8ZohPFuPdTqQ_ISfuI'
              onChange={val => setCaptVal(val)}
            />
            <div className='mt-[2vh]'>
              <button type="submit" disabled={!capVal} className='submit-btn'>
                Send Message
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
    </div>
  );
}

export default  ContactForm