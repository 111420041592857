import React, { useEffect, useState } from 'react'
import Typed from 'typed.js'

function MainWriter() {
  useEffect(() => {
    let typed = new Typed('.main-type', {
      strings: ['Frontend developer', 'UI|UX designer'],
      typeSpeed: 100,
      loop: true,
      fadeOut: true,
      backDelay: 1500,
      showCursor: true,
    })
    return () => {
      typed.destroy()
    }
  }, [])

  return (
    <h1 className='text-4xl font-[600]'>I'am <span className={`main-type  text-text_accented`}></span></h1>
  )
}

export default MainWriter